import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { IconDefinition, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { ModalIcon, ModalSettings } from './modal-model';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.css']
})
export class ModalGenericComponent {
  faCircleIcon:IconDefinition;
  settings:ModalSettings={};

  constructor( private dialogRef: DialogRef ) {
    this.settings = dialogRef.config.data;
    this.settings.paragraphSize =  this.settings.paragraphSize ? this.settings.paragraphSize :"w-[350px]";
    this.faCircleIcon = this.settings.icon == ModalIcon.Success ? faCircleCheck : faCircleXmark;
  }

  isSuccessColor ():boolean{
    return this.settings.icon == ModalIcon.Success;
  }

  closed() {
    this.dialogRef.close();
  }
}
