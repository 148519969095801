export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDSxjdg9DLfiUoPxs61T9DsGOBglFxECik",
    authDomain: "sza-prod-001.firebaseapp.com",
    projectId: "sza-prod-001",
    storageBucket: "storage.soho.market",
    messagingSenderId: "551570208833",
    appId: "1:551570208833:web:5b12be6f982ccdde656ec0",
    measurementId: "G-KZSB8BRS2J"
  },
  tenantId: 'prod-soho-bo-gdd1m',
  baseURL: "https://api.backoffice.soho.market/api/v1/",
  endpoints: {
    shop: 'shop',
    order: 'order',
    orders: 'orders',
    comment: 'comment',
    returns: 'returns',
    n_packages: 'n_packages',
    products: 'products',
    receipt: 'receipt',
    summary: 'summary',
    sendEnviameByorderId: 'sendEnviameByorderId',
    recoverPassword: 'recover-password',
    session: 'session',
    register: 'register',
    cities: "cities",
    propertyTypes: "property-types",
    properties: "properties",
    posts: "posts",
    emailServices: "email/services",
    emailContact: "email/contact",
    postAsFavorite: "mark-favorite",
    realState: "real-state",
    userData: "user-data",
    userPosts: "posts-user",
    uploadProducts: "upload/products",
    upload: "upload",
    productsEcommerce: "sync/list/products",
    uploadProductsEcommerce: "sync/pull",
    productsPending: "shadow/product",
    categoriesFilterPending: "shadow/category",
    categoriesShop: "category",
    editProductPrincipal: "shadow/product",
    tag: "tag",
    deleteProducts: "shadow/product",
    categoriesMarket: "marketplace/category",
    warehouseId: "warehouse",
    individualproduct: "individual-product",
    individualproducts: "individualProducts",
    product: "product",
    individualproductD: "individualProduct",
    typeAtributte: "typeAtributte",
    atributte: "atributte",
    individualProductVariant: "individualProductVariant",
    variantsIsMean: "variantsIsMean",
    warehouses: "warehouses",
    categoriesMarketPlace: "categoriesMarketPlace",
    variant: "variant",
    principalProduct: "principalProduct",
    executive: "executive",
    soho_manager: "SOHO_MANAGER",
    shop_manager: "SHOP_MANAGER",
    main_variants: "main-variants"
  },
  baseUrlMultivende: "https://dev.multivende.api.soho.market/api/v1/",
  endpointsMultivende: {
    status: "sync/status",
    clientId: "sync/client-id",
    accessToken: "sync/access-token",
    categories: "sync/categories",
    listCategories: "/sync/list/categories",
    listProducts: "/sync/list/products",
    listPrices: "/sync/price-lists",
    listWarehouses: "/sync/warehouses",
    listCategoriesMarket: "marketplace-category",
  },
  multivendeAuth: " https://app.multivende.com/apps/authorize",
  multivendeClientId: "623967816173",
  firebaseVerify: "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=AIzaSyDSxjdg9DLfiUoPxs61T9DsGOBglFxECik",
  googleMapApiKey: "",
  recaptcha: {
    publicKey: "0x4AAAAAAAJzdEg4xQ9YQASn"
  },
  emailNotFound: {
    emailMessage: "There is no user record corresponding to the provided email."
  },
  payloadEcommerce: 'soho-prod-uploads-metadata',
};
